.ant-input-number,
.ant-input,
.ant-input-affix-wrapper,
.ant-input-affix-wrapper-disabled,
.ant-input[disabled] {
  background: transparent;
  color: var(--text-primary);
  border-color: var(--stroke-secondary);
  border-radius: var(--radius-sm);

  &:hover {
    border-color: var(--stroke-primary) !important;
  }

  &.ant-input-number-focused {
    box-shadow: 0 0 0 1px var(--stroke-action-secondary);
    border-color: var(--stroke-action-secondary);
  }

  &.ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 1px var(--stroke-action-secondary);
    border-color: var(--stroke-action-secondary);
  }

  &:focus {
    box-shadow: 0 0 0 1px var(--stroke-action-secondary);
    border-color: var(--stroke-action-secondary);
  }

  &.err-suffix {
    .ant-input-suffix {
      color: var(--text-warn);
    }
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  color: var(--text-failure);
}

.ant-form-item-has-error {
  .ant-input-number,
  .ant-input,
  .ant-input-affix-wrapper {
    border-color: var(--stroke-failure);
  }
  .ant-input-prefix {
    color: var(--text-failure);
  }
}

.ant-form-item-has-error {
  .ant-input-number,
  .ant-input,
  .ant-input-affix-wrapper {
    background: transparent !important;
    background-color: transparent !important;
  }
}

.ant-input::placeholder {
  color: var(--text-placeholder);
}

.ant-empty-description {
  color: var(--text-secondary);
}

.antd-no-number-handler {
  .ant-input-number-handler-wrap {
    display: none;
  }
}

.ant-form-item-extra {
  color: var(--text-secondary);
}

/* Chrome, Safari, Edge, Opera */
input.ant-input::-webkit-outer-spin-button,
input.ant-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.ant-input[type='number'] {
  -moz-appearance: textfield;
}

.ant-input-prefix {
  color: var(--text-secondary);
}

.ant-picker,
.ant-picker-dropdown,
.ant-picker-panel-container {
  color: var(--text-secondary);
  background-color: var(--background-l0);
}

.ant-picker input {
  color: var(--text-primary);
}

.ant-picker-suffix,
.ant-picker-header,
.ant-picker-header button,
.ant-picker-cell,
.ant-picker-content th,
.ant-picker-input-placeholder {
  color: var(--text-secondary);
}

.ant-picker-cell:hover .ant-picker-cell-inner {
  background: var(--background-l2) !important;
}

input:-internal-autofill,
input:-internal-autofill:hover,
input:-internal-autofill:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill-selected {
  background: transparent !important;
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  background-image: none !important;
}

.ant-input-affix-wrapper.align-end > input {
  text-align: end;
}

.ant-form-item-label > label {
  color: var(--text-primary);
}

.ant-input-textarea-show-count::after {
  color: var(--text-tertiary);
}

.ant-select {
  .ant-select-selector {
    background-color: transparent !important;
    color: var(--text-primary);
    border-color: var(--stroke-secondary) !important;
  }

  .ant-select-arrow {
    color: var(--text-primary);
  }
}

.ant-select.small {
  .ant-select-selector {
    height: 24px;
    border-color: var(--stroke-tertiary) !important;
  }
  .ant-select-selection-item {
    line-height: 22px;
    color: var(--text-secondary);
    font-weight: 500;
  }
  .ant-select-arrow {
    margin-top: -4px;
    font-size: 10px;
  }
}

.ant-select-dropdown {
  padding: 0;
  background-color: var(--background-l0);

  .ant-select-item-option {
    color: var(--text-primary);
  }

  .ant-select-item-option-active {
    color: var(--text-primary);
    background-color: var(--background-l2);
  }

  .ant-select-item-option-selected {
    color: var(--text-primary);
    background-color: var(--background-l2);
  }
}

// Specific to language Select in top nav
.language-selector {
  display: flex;
  width: 100%;
  align-items: center;
  .ant-select {
    border: none !important;
    margin-left: 4px;
    width: 100%;
  }

  .ant-select-selector {
    height: 24px;
    padding: 0 8px !important;
    border: none !important;
  }
  .ant-select-arrow {
    display: none;
  }
  .ant-select-selection-item {
    text-align: left;
    padding: 0 !important;
    font-weight: 400;
  }
}

.language-selector .ant-select-focused .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.ant-upload-list,
.ant-upload-list-text {
  color: var(--text-secondary);
}

.ant-upload-list-item:hover {
  .ant-upload-list-item-info {
    background: transparent;
  }
}

.ant-upload-text-icon > span {
  color: var(--icon-secondary) !important;
}

.ant-upload-list-item-card-actions {
  .anticon {
    color: var(--text-secondary);
  }
}

.ant-form-item-control-input {
  min-height: 4px; // get error messages appearing closer to the input fields
}

// added this rule for when an the 'extra' prop is passed to FormItem (error message needs more space in this case)
.ant-form-item-control-input-extra-and-error .ant-form-item-control-input {
  min-height: 23px;
}
.ant-input-group-addon {
  background: transparent;
}

.ant-input-search-button {
  border: 1px solid var(--stroke-primary);
  color: var(--icon-action-primary) !important;
}

.anticon.ant-input-clear-icon {
  color: var(--icon-secondary);

  &:hover {
    color: var(--icon-primary);
  }
}

// To align new currency prop in FormattedNumberInput
.formatted-number-input {
  display: flex;
  width: 100%;
}

// added a wrapping Form.Item on FormattedNumberInput which gave it Form.Item's margin-bottom
.formatted-number-input .ant-form-item {
  margin-bottom: 0;
  width: 100%;
}
