.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--background-action-primary);
  border-color: var(--stroke-action-primary);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--stroke-action-primary);
}
