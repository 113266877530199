.ant-tooltip-inner {
  color: var(--text-primary);
  border-radius: var(--radius-md);
  background: var(--background-l0);
  border: 1px solid var(--stroke-tertiary);
  padding: 12px 16px;
  box-shadow: 0 4px 12px #00000064;
  font-size: 0.85rem;
}

.ant-tooltip-arrow-content {
  background: var(--stroke-tertiary);
}

.ant-tooltip-disabled-compatible-wrapper.block {
  display: block !important;
}
