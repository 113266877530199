.ant-statistic-content {
  font-family: 'DM Mono', monospace;
}

.ant-statistic-content {
  font-size: 1.1rem;
}

.ant-statistic-title {
  color: var(--text-brand-primary);
}

.ant-statistic-content {
  color: var(--text-primary);
}
