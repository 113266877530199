.bn-onboard-modal {
  z-index: 9999;
}

.bn-onboard-modal-content {
  // !important tags used to override blocknative's theme-based classnames
  background: var(--background-l0) !important;
  font-family: 'DM Mono', monospace !important;

  p {
    color: var(--text-primary);
  }

  // All buttons in modal
  button.bn-onboard-custom {
    border: 1px solid transparent;

    &:hover {
      background: none;
      &.bn-onboard-dark-mode-background-hover {
        box-shadow: none;
        color: var(--text-action-primary);
      }
    }
  }

  // "Show more" button
  button.bn-onboard-prepare-button {
    color: var(--text-action-primary);
    border: 1px solid var(--stroke-action-secondary);
    font-weight: 500;
  }

  // Header icon
  div.bn-onboard-modal-content-header-icon {
    background: var(--background-l1);
  }

  // Close icon
  .bn-onboard-modal-content-close:hover {
    background: none !important;
  }

  // Links
  div.svelte-w9ftfy span.svelte-w9ftfy,
  a {
    color: var(--text-action-primary) !important;
  }
}
