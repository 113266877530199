.ant-btn {
  background: transparent;
  border-color: var(--stroke-action-secondary);
  color: var(--text-over-action-secondary);
  font-weight: 500;
  border-radius: var(--radius-sm);

  &:hover {
    background: transparent;
    border-color: var(--stroke-action-primary);
    color: var(--text-action-primary);
  }

  &:focus,
  &.ant-btn-loading {
    background: transparent;
    border-color: var(--stroke-action-secondary);
    color: var(--text-over-action-secondary);
  }
}

.ant-btn-primary {
  &:not(.ant-btn[disabled]) {
    background: var(--background-action-primary) !important;
    border-color: transparent !important;
    color: var(--text-over-action-primary) !important;
  }

  &:hover {
    background: var(--background-action-highlight);
    border-color: var(--stroke-action-primary);
    color: var(--text-over-action-primary);
  }

  &:focus,
  &.ant-btn-loading {
    background: var(--background-action-primary);
    border-color: var(--stroke-action-primary);
    color: var(--text-over-action-primary);
  }
}

.ant-btn-primary {
  text-shadow: none;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08);
  border-color: transparent;

  &:hover {
    border-color: transparent;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.12);
  }
}

.ant-btn[disabled] {
  color: var(--text-over-disabled);
  border-color: var(--stroke-disabled);
  background: transparent;

  &.ant-btn-primary {
    background: var(--background-disabled);
    border-color: transparent;

    &:hover {
      background: var(--background-disabled);
      border-color: transparent;
    }
  }

  &:hover {
    color: var(--text-over-disabled);
    border-color: var(--stroke-disabled);
    background: transparent;
    opacity: 0.7;
  }
}

.ant-btn-text {
  border: transparent;
}

.ant-btn-link {
  color: var(--text-action-primary);
  border: none;

  &:hover {
    color: var(--text-action-highlight);
  }
  &:focus,
  &:active {
    color: var(--text-action-primary);
  }
}

.feedback-button {
  position: fixed;
  background-color: var(--background-l0);
  right: 56px;
  bottom: 40px;
  padding: 4px 10px;
  width: 70px;
  height: 70px;
  margin: 3px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  transition: 0.3s;
  cursor: pointer;
  img {
    width: 60px;
    margin-left: -3px;
    transition: 0.3s;
  }
}

.feedback-button.dark {
  background-color: var(--background-l2);
  box-shadow: 0px 8px 12px #000000;
}

.feedback-button.ant-tooltip-open {
  background-color: var(--background-l0);
  box-shadow: 0px 8px 12px 2px rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
  img {
    transform: scale(1.05);
  }
  .show-text-hover {
    display: inline-block !important;
  }
}

.feedback-button.ant-tooltip-open.dark {
  background-color: var(--background-l2);
  box-shadow: 0px 8px 12px 2px #000000;
}
