.ant-collapse {
  background: var(--background-l0);
  border-color: var(--stroke-secondary);

  .ant-collapse-item {
    border-color: var(--stroke-secondary);

    .ant-collapse-header {
      font-weight: 500;
      color: var(--text-header);
    }
  }

  .ant-collapse-content,
  .ant-collapse-content-box {
    background-color: transparent;
    border: none;
    color: var(--text-primary);
  }
}

.ant-collapse.minimal {
  .ant-collapse-content-box {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-collapse-header {
    display: flex;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    width: 100%;

    .ant-collapse-arrow {
      left: 0;
    }
  }
}

// Top Nav Select
// Right side of the top nav
.top-nav {
  // Resources dropdown and options (dotdotdot) menu
  .ant-collapse-header {
    color: var(--text-primary) !important;
    padding-bottom: 8px !important;
  }

  .ant-collapse-content {
    float: right;
    margin-right: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 2px;
    background-color: var(--background-l0);
    border-radius: 2px;
  }
  .ant-collapse-content-box {
    border: 1px solid var(--stroke-tertiary);
    padding: 0;
    width: 100%;
    background-color: var(--background-l0);
  }

  .ant-select-arrow {
    display: none;
  }
}

.ant-collapse-content-inactive {
  display: none;
}

.top-nav-mobile {
  .ant-collapse {
    width: 100%;
    margin-bottom: 4px;
    height: 55px;
  }
  .ant-collapse-content {
    float: left;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
  }
  .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    z-index: 1000;
    margin-top: -2px;
    padding: 7px 0 15px;
    border: none;
    border-bottom: 1px solid var(--stroke-secondary);
  }
  .nav-menu-item {
    padding: 10px 15px;
  }

  .nav-subsection {
    > div {
      display: flex;
      align-items: center;
    }
  }
}

.projects-filter-collapse .ant-collapse-content {
  position: absolute;
  z-index: 999;
  background-color: var(--background-l0);
  width: 180px;
  box-shadow: 0px 8px 12px var(--boxShadow-primary);

  .ant-collapse-content-box {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.projects-filter-collapse.dark .ant-collapse-content {
  background-color: var(--background-l1);
}

// Trending window days selector on trending projects section of homepage
.trending-window-select {
  background: none;
  .ant-collapse-header {
    font-size: 22px;
    font-weight: 600 !important;
    color: var(--text-header) !important;
    min-width: 80px;
    text-decoration: underline;
  }

  .ant-collapse-content {
    position: absolute;
    background: var(--background-l0);
    z-index: 999;
    margin-left: 10px;
    box-shadow: 0px 8px 12px var(--boxShadow-primary);
    width: 114px;
  }
  .ant-collapse-content-box {
    padding: 0;
  }
  .select-item {
    font-weight: 500;
    font-size: 16px;
    padding: 10px 15px;
    cursor: pointer;
  }
}

.ant-collapse-content-hidden {
  display: none !important;
}
