.ant-menu,
.ant-menu-item,
.ant-menu-item > a {
  color: var(--text-primary) !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.ant-layout-header {
  color: var(--text-primary);
  z-index: 99;
}
